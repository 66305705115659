import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Hero from "../components/CookiesPage/Hero"
import Content from "../components/CookiesPage/Content"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Home() {
  return (
    <>
      <Helmet
        title="Integra Global Solutions Customer Privacy Policy"
        meta={[
          {
            name: `description`,
            content:
              "Customers privacy is of great significance to Integra Global Solutions. Take a moment to find out our privacy policy before signing up for a service.",
          },
          {
            name: "keywords",
            content:
              "Privacy Policy, Customer Privacy, Confidentiality, Information Security, Business Information, Integra Privacy,",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Integra Global Solutions Customer Privacy Policy"
        />
        <meta
          property="og:description"
          content="Customers privacy is of great significance to Integra Global Solutions. Take a moment to find out our privacy policy before signing up for a service."
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content="Integra Global Solutions Customer Privacy Policy"
        />
        <meta
          name="twitter:description"
          content="Customers privacy is of great significance to Integra Global Solutions. Take a moment to find out our privacy policy before signing up for a service."
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero
            obj={{
              path: "privacy",
              title: "Cookies",
            }}
          />
          <Content />
        </main>
      </Layout>
    </>
  )
}
