import React from "react"
import Styled from "./styled.module.css"

export default function Content() {
  return (
    <div className="container">
      <section className={Styled.content}>
        <h1 className="sm-title bold">What are Cookies?</h1>
        <p className="lg-text">
          Cookies are small pieces of data sent from a website and stored on a
          visitor's browser. They are typically used to keep track of settings
          you've selected and actions taken on a site.
        </p>
        <h2 className="sm-title bold">There are two types of cookies:</h2>
        <p className="lg-text">
          Session (transient) cookies: These cookies are erased when you close
          your browser, and do not collect information from your computer. They
          typically store information in the form of a session identification
          that does not personally identify the user.
        </p>
        <p className="lg-text">
          Persistent (permanent or stored) cookies: These cookies are stored on
          your hard drive until they expire (at a set expiration date) or until
          you delete them. These cookies are used to collect identifying
          information about the user, such as web surfing behavior or user
          preferences for a specific site. We do not track any cookies for our
          websites. Third party tools that we use on our website, as an example:
          Google analytics may track cookies to gather information to provide
          user behavior data to us. We also provide links from our website
          pointing to our business pages in Facebook, Linkedin and so on. Please
          refer to their policies regarding cookies.
        </p>
      </section>
    </div>
  )
}
